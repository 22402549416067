@font-face {
  font-family: "FiraSans-Bold";
  font-display: auto;
  src: local("FiraSans-Bold"), url(./assets/fonts/FiraSans-Bold.woff2) format("truetype");
}

@font-face {
  font-family: "FiraSans-Medium";
  font-display: auto;
  src: local("FiraSans-Medium"), url(./assets/fonts/FiraSans-Medium.woff2) format("truetype");
}

@font-face {
  font-family: "FiraSans-Regular";
  font-display: auto;
  src: local("FiraSans-Regular"), url(./assets/fonts/FiraSans-Regular.woff2) format("truetype");
}

@font-face {
  font-family: "NunitoSans-Bold";
  font-display: auto;
  src: local("NunitoSans-Bold"), url(./assets/fonts/NunitoSans-Bold.woff2) format("truetype");
}

@font-face {
  font-family: "NunitoSans-Regular";
  font-display: auto;
  src: local("NunitoSans-Regular"), url(./assets/fonts/NunitoSans-Regular.woff2) format("truetype");
}

@font-face {
  font-family: "NunitoSans-SemiBold";
  font-display: auto;
  src: local("NunitoSans-SemiBold"), url(./assets/fonts/NunitoSans-SemiBold.woff2) format("truetype");
}

h2#swal2-title {
  font-size: 24px;
  font-family: "FiraSans-Bold";
}
div#swal2-html-container {
  font-size: 16px;
  font-family: "NunitoSans-Bold";
}

.swal2-container{
  z-index: 9999 !important;
}
