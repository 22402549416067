.title {
    font-family: FiraSans-Bold;
}

.message {
    font-family: FiraSans-Regular;
}

.swal2-container {
    z-index: 9999999999999;
  }

::ng-deep.swal2-container {
    z-index: 1000000;
}

.swal-overlay {
    z-index: 100000000000; 
}